import { type FlowProcess } from '@/features/FlowRouter';

import { carFlowSteps } from '@/shared/router/flowSteps';

// Note: страницы корнер кейсов, нужно добавлять в самый конец
export const carFlowOrder: FlowProcess[] = [
  {
    name: carFlowSteps.PHONE,
    // isForwardSkip: ({ phone }) => Boolean(phone),
  },
  {
    name: carFlowSteps.PLATE_NUMBER,
  },
  {
    name: carFlowSteps.CAR_DETAILS,
    possibleBackwardTo: new Set([carFlowSteps.PLATE_NUMBER, carFlowSteps.CHASSIS_NUMBER]),
  },
  {
    name: carFlowSteps.CHASSIS_NUMBER,
    possibleBackwardTo: new Set([carFlowSteps.PLATE_NUMBER]),
    // isForwardSkip: ({ chassisNumber }) => Boolean(chassisNumber),
  },
  {
    name: carFlowSteps.CANNOT_INSURE_ONLINE_ERROR,
    possibleBackwardTo: new Set([carFlowSteps.PLATE_NUMBER, carFlowSteps.CHASSIS_NUMBER]),
  },
  {
    name: carFlowSteps.START_POLICY_DATE,
    possibleBackwardTo: new Set([carFlowSteps.CAR_DETAILS]),
    // isSkip: ({ startPolicyDate }) => Boolean(startPolicyDate),
  },
  {
    name: carFlowSteps.ONLY_OFFLINE,
    possibleBackwardTo: new Set([carFlowSteps.POLICY_HOLDER_INFORMATION]),
  },
  {
    name: carFlowSteps.VEHICLE_CHECK,
    possibleBackwardTo: new Set([carFlowSteps.PLATE_NUMBER, carFlowSteps.CHASSIS_NUMBER]),
    // onBackward: (prevRoute) => {
    //   if (prevRoute === null) return carFlowSteps.CAR_DETAILS;
    //   const mapBackward = {
    //     [carFlowSteps.PLATE_NUMBER]: carFlowSteps.CHASSIS_NUMBER,
    //     [carFlowSteps.CHASSIS_NUMBER]: carFlowSteps.CAR_DETAILS,
    //   };
    //   return mapBackward[prevRoute.current.name] ?? carFlowSteps.CAR_DETAILS;
    // },
  },
  {
    name: carFlowSteps.ACTIVE_POLICY_ERROR,
    possibleBackwardTo: new Set([
      carFlowSteps.PLATE_NUMBER,
      carFlowSteps.CHASSIS_NUMBER,
      carFlowSteps.VEHICLE_CHECK,
    ]),
  },
  {
    name: carFlowSteps.SELECT_PLAN,
    possibleBackwardTo: new Set([carFlowSteps.VEHICLE_CHECK, carFlowSteps.START_POLICY_DATE]),
  },
  {
    name: carFlowSteps.COVERAGE_AREA,
  },
  {
    name: carFlowSteps.POLICY_HOLDER_INFORMATION,
  },
  {
    name: carFlowSteps.INSURED_DRIVERS,
  },
  {
    name: carFlowSteps.QUOTE,
  },
  {
    name: carFlowSteps.UPLOAD_DOCUMENTS,
  },
];
