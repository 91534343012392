<script setup lang="ts">
import { computed } from 'vue';
import { type NavigationHookAfter, useRoute, useRouter } from 'vue-router';
// @TODO: replace to feature
import FooterWidget from '@/widgets/FooterWidget';
import HeaderWidget from '@/widgets/HeaderWidget';
import { type ThemeColor } from '@/features/FlowRouter';
import { usePushEvent } from '@/features/PushEvent';
import { onBeforeUnmount, onMounted } from 'vue';
import { useRtl } from '@/shared/composables';

const emit = defineEmits(['back']);
const handleOnBack = () => {
  emit('back');
};

const route = useRoute();
const routeMeta = computed(() => route.meta);
const themeColor = computed(() => (routeMeta.value?.themeColor as ThemeColor) ?? 'light');
const showFooter = computed(() => (routeMeta.value?.withFooter as boolean) ?? true);

const pushEvent = usePushEvent();
const router = useRouter();
let unregisterRouterHook = () => {};
onMounted(() => {
  const pushEventRouterHook: NavigationHookAfter = async (_, from) => {
    await pushEvent(from);
  };
  unregisterRouterHook = router.afterEach(pushEventRouterHook);
});

onBeforeUnmount(() => {
  unregisterRouterHook();
});

const { isRtl } = useRtl();
</script>

<template>
  <div class="flow-widget" :class="{ rtl: isRtl, 'flow-widget--primary': themeColor === 'dark' }">
    <div class="flow-widget__content">
      <HeaderWidget :theme-color="themeColor" @back="handleOnBack" />
      <slot />
      <FooterWidget v-if="showFooter" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'FlowWidget';
</style>
