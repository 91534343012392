import type { FlowProcess } from '@/features/FlowRouter';

import { renewFlowSteps } from '@/shared/router/flowSteps';

// Note: страницы корнер кейсов, нужно добавлять в самый конец
export const renewFlowOrder: FlowProcess[] = [
  {
    name: renewFlowSteps.RENEWAL_LOGIN,
  },
  {
    name: renewFlowSteps.POLICY_INFORMATION,
  },
  {
    name: renewFlowSteps.POLICY_HOLDER_INFORMATION,
  },
  {
    name: renewFlowSteps.INSURED_DRIVERS,
    possibleBackwardTo: new Set([
      renewFlowSteps.POLICY_INFORMATION,
      renewFlowSteps.POLICY_HOLDER_INFORMATION,
    ]),
  },
  {
    name: renewFlowSteps.QUOTE,
  },
  {
    name: renewFlowSteps.CANNOT_RENEW_ERROR,
    possibleBackwardTo: new Set([renewFlowSteps.RENEWAL_LOGIN]),
    isCornerCasePage: true,
  },
  {
    name: renewFlowSteps.POLICY_ACTIVE_SOON_ERROR,
    possibleBackwardTo: new Set([renewFlowSteps.RENEWAL_LOGIN]),
    isCornerCasePage: true,
  },
  {
    name: renewFlowSteps.CAN_BUY_NEW_POLICY_ERROR,
    possibleBackwardTo: new Set([renewFlowSteps.RENEWAL_LOGIN]),
    isCornerCasePage: true,
  },
  {
    name: renewFlowSteps.FOUND_ACTIVE_POLICY_ERROR,
    possibleBackwardTo: new Set([renewFlowSteps.RENEWAL_LOGIN]),
    isCornerCasePage: true,
  },
];
