import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia();

pinia.use(({ store, app }) => {
  store.$route = app.config.globalProperties.$route;
  store.$router = app.config.globalProperties.$router;
  store.$i18n = app.config.globalProperties.$i18n;
});
pinia.use(piniaPluginPersistedstate);

export default pinia;
