import { defineStore } from 'pinia';

import { TYPES_FEE } from '@/entities/quote';

import { useApi } from '@/shared/composables/';
import type {
  FeesInfo,
  RequestGetTotalPrice,
  ResponseDocumentList,
  ResponseGetTotalPrice,
  RequestSendDocument,
} from '@/shared/api/FlowService/FlowService.types';
import { regionNames } from '@/shared/constants/regionNamesMapping';

import type { Quote } from './Quote.types';

export const useQuoteStore = defineStore('QuoteStore', {
  persist: {
    key: 'oman-car-quote',
  },
  state: (): Quote => ({
    quotes: [],
    quoteNumber: '',
    productCode: '',
    geoAreaName: '',
    geoAreaCode: '',
    quoteNameCode: '',
    policyNumber: '',
    policyStatus: '',
    startPolicy: new Date(),
    totalPrice: 0,
    additionalCovers: [],
    vat: 0,
    support: 0,
    localInsurance: 0,
    emergencyFee: 0,
  }),
  getters: {
    getAdditionalCoversPrice: (state) =>
      state.additionalCovers.reduce((accum, current) => accum + current.value, 0),
    getChosenScheme: (state) =>
      state.quotes
        .find((quote) => quote.quoteno === state.quoteNumber)
        ?.schemes.find((scheme) => scheme.productcode === state.productCode),
  },
  actions: {
    setQuotes(quotes: Quote['quotes']) {
      this.quotes = quotes;
    },

    setAdditionalCovers(covers: any) {
      this.additionalCovers = covers;
    },

    setQuoteNumber(quoteNumber: string) {
      this.quoteNumber = quoteNumber;
    },

    setProductCode(productCode: string) {
      this.productCode = productCode;
    },

    setQuoteNameCode(code: string) {
      this.quoteNameCode = code;
    },

    setGeoArea(code: string) {
      this.geoAreaCode = code;
      this.geoAreaName = regionNames[code];
    },

    setStartPolicyDate(date: Date) {
      this.startPolicy = date;
    },

    setPolicyStatus(policyStatus: string) {
      this.policyStatus = policyStatus;
    },

    setPolicyNumber(policyNumber: string) {
      this.policyNumber = policyNumber;
    },
    // TODO: удалить
    async getTotalPrice(data: RequestGetTotalPrice) {
      const response = await useApi().flowService.fetchTotalPrice(data);
      if (response) {
        this.totalPrice = response.totalprice;
        this.vat = response.taxAmount;

        const findPremium = (type: string) => {
          const premium: string | undefined = response?.covers?.feesInfo?.find(
            (value: FeesInfo) => value.code === type,
          )?.premium;
          return premium ? Number(premium) : 0;
        };

        this.support = findPremium(TYPES_FEE.SUPPORT_CODE);
        this.localInsurance = findPremium(TYPES_FEE.LOCAL_INSURANCE);
        this.emergencyFee = findPremium(TYPES_FEE.EMERGENCY_FEE);
      }
    },

    // TODO: странная логика. Может быть получится упростить?
    setQuoteInfo(data: ResponseGetTotalPrice) {
      this.totalPrice = data.totalprice;
      this.vat = data.taxAmount;

      const findPremium = (type: string) => {
        const premium: string | undefined = data?.covers?.feesInfo?.find(
          (value: FeesInfo) => value.code === type,
        )?.premium;
        return premium ? Number(premium) : 0;
      };

      this.support = findPremium(TYPES_FEE.SUPPORT_CODE);
      this.localInsurance = findPremium(TYPES_FEE.LOCAL_INSURANCE);
      this.emergencyFee = findPremium(TYPES_FEE.EMERGENCY_FEE);
    },

    // TODO: удалить
    async sendDocument(requestSendDocument: RequestSendDocument, callback: any) {
      return await useApi().flowService.sendDocument(requestSendDocument, callback);
    },

    // TODO: удалить
    async getDocumentList(quoteNumber: string): Promise<ResponseDocumentList[]> {
      return await useApi().flowService.getDocumentList(quoteNumber);
    },
  },
});
