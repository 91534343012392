import { defineStore } from 'pinia';

import type { PolicyHolder, LicenseInfo } from './PolicyHolder.types';

export const usePolicyHolderStore = defineStore('PolicyHolderStore', {
  persist: {
    key: 'oman-car-policyholder',
  },
  state: (): PolicyHolder => ({
    dateOfBirth: null,
    drivingExperienceOman: null,
    email: '',
    familyName: '',
    firstName: '',
    licenseNumber: '',
    phone: '',
    isDateOfBirthEditable: true,
    isDrivingExpEditable: true,
    isLicenseCorrect: null,
    licenseErrorName: null,
    srNo: 1,
  }),
  getters: {
    transId: (state) => state.licenseNumber.substring(state.licenseNumber.length - 4),
    getDrivingExperienceOman: (state) =>
      state.drivingExperienceOman?.toString() ? state.drivingExperienceOman.toString() : null,
  },
  actions: {
    updateStoreData(payload: Partial<PolicyHolder>) {
      this.$patch(payload);
    },

    setLicenseInfo(payload: LicenseInfo) {
      this.updateStoreData({ licenseErrorName: null });

      const experienceExist = payload.drivingExp !== null && payload.drivingExp >= 0;

      this.updateStoreData({
        licenseNumber: payload.licenseNumber,
        isLicenseCorrect: !!payload.dateOfBirth,
      });

      if (payload.dateOfBirth) {
        this.updateStoreData({ dateOfBirth: payload.dateOfBirth, isDateOfBirthEditable: false });
      } else {
        this.updateStoreData({ dateOfBirth: null, isDateOfBirthEditable: true });
      }

      if (experienceExist) {
        this.updateStoreData({
          drivingExperienceOman: payload.drivingExp,
          isDrivingExpEditable: false,
        });
      } else {
        this.updateStoreData({ drivingExperienceOman: null, isDrivingExpEditable: true });
      }
    },

    setLicenseError(errorCode: string) {
      this.updateStoreData({ licenseErrorName: errorCode });
      this.resetLicenseInfo();
    },

    resetLicenseInfo() {
      this.updateStoreData({
        dateOfBirth: null,
        drivingExperienceOman: null,
        isDateOfBirthEditable: true,
        isDrivingExpEditable: true,
        isLicenseCorrect: false,
      });
    },
  },
});
