import { inject } from 'vue';
import { createRouter, createWebHistory, type RouteRecordRaw, RouterView } from 'vue-router';

import { type FlowProcess } from '@/features/FlowRouter';

import { carFlowSteps, renewFlowSteps } from '@/shared/router/flowSteps';

import { getLocaleFromUrl } from '../plugins/i18n';
import { PRODUCTS } from '../app.constants';
import { FlowView } from '../flow';
import { carFlowOrder } from './car-insurance';
import { renewFlowOrder } from './renewal';

export const flowOrderMap: Record<string, FlowProcess[]> = {
  [PRODUCTS.CAR_INSURANCE]: carFlowOrder,
  [PRODUCTS.RENEWAL]: renewFlowOrder,
};

const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging';
const FLOW_ROOT = 'FlowRoot';
const NOT_FOUND = 'NotFound';

const { locale } = getLocaleFromUrl();
export const BASE_URL: string =
  {
    en: import.meta.env.VITE_APP_EN_BASE_URL,
    ar: import.meta.env.VITE_APP_AR_BASE_URL,
  }[locale] ?? '/';

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes: [
    {
      path: '/:locale',
      name: FLOW_ROOT,
      children: [
        {
          path: `${PRODUCTS.CAR_INSURANCE}/flow`,
          name: PRODUCTS.CAR_INSURANCE,
          component: FlowView,
          beforeEnter(to, __, next) {
            if (to.name !== PRODUCTS.CAR_INSURANCE) return next();
            const locale = inject('locale');
            // @ts-ignore
            next({ name: carFlowSteps.PHONE, params: { locale }, replace: true });
          },
          children: [
            {
              path: 'phone',
              name: carFlowSteps.PHONE,
              component: () => import('@/pages/PhonePage/'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'plate-number',
              name: carFlowSteps.PLATE_NUMBER,
              component: () => import('@/pages/PlateNumber/'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'chassis-number',
              name: carFlowSteps.CHASSIS_NUMBER,
              component: () => import('@/pages/ChassisNumber/'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'car-details',
              name: carFlowSteps.CAR_DETAILS,
              component: () => import('@/pages/CarDetails.vue'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'vehicle-check',
              name: carFlowSteps.VEHICLE_CHECK,
              component: () => import('@/pages/VehicleCheck/'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'start-policy-date',
              name: carFlowSteps.START_POLICY_DATE,
              component: () => import('@/pages/SelectPolicyDate/'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'select-plan',
              name: carFlowSteps.SELECT_PLAN,
              component: () => import('@/pages/SelectPlan/'),
            },
            {
              path: 'select-coverage-area',
              name: carFlowSteps.COVERAGE_AREA,
              component: () => import('@/pages/CoverageArea/'),
            },
            {
              path: 'policyholder',
              name: carFlowSteps.POLICY_HOLDER_INFORMATION,
              component: () => import('@/pages/PolicyHolderInformation/'),
            },
            {
              path: 'insured-drivers',
              name: carFlowSteps.INSURED_DRIVERS,
              component: () => import('@/pages/InsuredDrivers/'),
            },
            {
              path: 'quote',
              name: carFlowSteps.QUOTE,
              component: () => import('@/pages/QuotePage/'),
            },
            {
              path: 'upload-documents',
              name: carFlowSteps.UPLOAD_DOCUMENTS,
              component: () => import('@/pages/UploadDocuments/'),
            },
            {
              path: 'redirect',
              name: carFlowSteps.REDIRECT,
              component: () => import('@/pages/RedirectPage.vue'),
              meta: {
                withFooter: false,
                withBackButton: false,
                themeColor: 'dark',
                withProgressBar: false,
              },
            },
            {
              path: 'insurance-result',
              name: carFlowSteps.INSURANCE_RESULT,
              component: () => import('@/pages/InsuranceResult/'),
              meta: {
                withProgressBar: false,
                withBackButton: false,
              },
            },
            {
              path: 'check-documents',
              name: carFlowSteps.CHECK_DOCUMENTS,
              component: () => import('@/pages/ErrorsPage/ui/CheckDocuments.vue'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'active-policy',
              name: carFlowSteps.ACTIVE_POLICY_ERROR,
              component: () => import('@/pages/ErrorsPage/ui/ActivePolicy.vue'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'cannot-insure-online',
              name: carFlowSteps.CANNOT_INSURE_ONLINE_ERROR,
              component: () => import('@/pages/ErrorsPage/ui/CannotInsureOnline.vue'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'only-offline',
              name: carFlowSteps.ONLY_OFFLINE,
              component: () => import('@/pages/ErrorsPage/ui/OnlyOffline.vue'),
              meta: {
                withProgressBar: false,
              },
            },
          ],
        },
        {
          path: `${PRODUCTS.RENEWAL}/flow`,
          name: PRODUCTS.RENEWAL,
          component: FlowView,
          beforeEnter(to, __, next) {
            if (to.name !== PRODUCTS.RENEWAL) return next();
            const locale = inject('locale');
            // @ts-ignore
            next({ name: renewFlowSteps.RENEWAL_LOGIN, params: { locale }, replace: true });
          },
          children: [
            {
              path: 'renewal-login',
              name: renewFlowSteps.RENEWAL_LOGIN,
              component: () => import('@/pages/RenewalLogin/'),
            },
            {
              path: 'policy-information',
              name: renewFlowSteps.POLICY_INFORMATION,
              component: () => import('@/pages/PolicyInformation/'),
            },
            {
              path: 'policyholder',
              name: renewFlowSteps.POLICY_HOLDER_INFORMATION,
              component: () => import('@/pages/PolicyHolderInformation/'),
            },
            {
              path: 'insured-drivers',
              name: renewFlowSteps.INSURED_DRIVERS,
              component: () => import('@/pages/InsuredDrivers/'),
            },
            {
              path: 'quote',
              name: renewFlowSteps.QUOTE,
              component: () => import('@/pages/QuotePage/'),
            },
            {
              path: 'redirect',
              name: renewFlowSteps.REDIRECT,
              component: () => import('@/pages/RedirectPage.vue'),
              meta: {
                withFooter: false,
                withBackButton: false,
                themeColor: 'dark',
                withProgressBar: false,
              },
            },
            {
              path: 'insurance-result',
              name: renewFlowSteps.INSURANCE_RESULT,
              component: () => import('@/pages/InsuranceResult/'),
              meta: {
                withProgressBar: false,
                withBackButton: false,
              },
            },
            {
              path: 'cannot-renew',
              name: renewFlowSteps.CANNOT_RENEW_ERROR,
              component: () => import('@/pages/ErrorsPage/ui/CannotRenew.vue'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'policy-active-soon',
              name: renewFlowSteps.POLICY_ACTIVE_SOON_ERROR,
              component: () => import('@/pages/ErrorsPage/ui/ActiveSoon.vue'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'found-active-policy',
              name: renewFlowSteps.FOUND_ACTIVE_POLICY_ERROR,
              component: () => import('@/pages/ErrorsPage/ui/FoundActivePolicy.vue'),
              meta: {
                withProgressBar: false,
              },
            },
            {
              path: 'can-buy-new-policy',
              name: renewFlowSteps.CAN_BUY_NEW_POLICY_ERROR,
              component: () => import('@/pages/ErrorsPage/ui/NewPolicy.vue'),
              meta: {
                withProgressBar: false,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: NOT_FOUND,
      component: () => import('@/pages/NotFound.vue'),
      beforeEnter(_, __, next) {
        if (isDev) return next();

        window.location.href = '/en/404';
      },
    },
  ],
});

const devRoutes: { [key: string]: RouteRecordRaw } = {
  root: {
    path: '',
    component: RouterView,
    name: 'Root',
    beforeEnter(_, __, next) {
      // @ts-ignore
      next({ name: NOT_FOUND, replace: true });
    },
  },
  rootWithLocale: {
    path: '',
    name: 'RootWithLocale',
    component: RouterView,
    beforeEnter(_, __, next) {
      next({ name: NOT_FOUND, replace: true });
    },
  },
};

if (isDev) {
  router.addRoute(devRoutes.root);
  router.addRoute(FLOW_ROOT, devRoutes.rootWithLocale);
}

export default router;
