import { defineStore } from 'pinia';

// TODO: идет в lib
import { VEHICLE_USAGE_PRIVATE_CODE } from '@/entities/Car/lib';
// TODO: entity идет в entity
import { checkValidBodyCode } from '@/entities/Car';

import { useApi } from '@/shared/composables';
import type { PolicyInfoCar, VehicleSpecs } from '@/shared/api/FlowService';
import type { ApiFields } from '@/shared/api';

import type { Car } from './Car.types';

export const useCarStore = defineStore('CarStore', {
  persist: {
    key: 'oman-car-vehicle',
  },
  state: (): Car => ({
    chassisNumber: '',
    model: { name: '', code: '' },
    brand: { name: '', code: '' },
    modelYear: null,
    seats: '',
    cylinders: '',
    cylindersCode: '',
    firstRegistrationYear: null,
    vehicleUsage: { name: '', code: '' },
    body: { name: '', code: '' }, // TODO: think about naming, original: vehicleType
    plateCharacter: '',
    plateNumber: '',
    insuranceEndDate: null, // TODO: Policy/Quote entity
    admeId: 0,
    engineNumber: null,
    isNoPlateNumber: false,
    isPlateNotFound: null,
  }),
  getters: {
    bodyCode: (state) => state.body.code,
    bodyCodeIsUnavailable: (state) => !checkValidBodyCode(state.body.code),
    usageCode: (state) => state.vehicleUsage.code,
    usageCodeIsUnavailable: (state) => state.vehicleUsage.code !== VEHICLE_USAGE_PRIVATE_CODE,
    isDataGetSpecFilled(state): boolean {
      return Boolean(state.admeId && state.brand?.code && state.modelYear && state.model?.code);
    },
  },
  actions: {
    setChassisNumber(chassisNumber: string) {
      this.chassisNumber = chassisNumber.toUpperCase();
    },

    setModel(model: NameCode) {
      this.model = model;
    },

    setBrand(brand: NameCode) {
      this.brand = brand;
    },

    setModelYear(modelYear: Nullable<number>) {
      this.modelYear = modelYear;
    },

    setSeats(seats: string) {
      this.seats = seats;
    },

    setCylinders(cylinders: string) {
      this.cylinders = cylinders;
    },

    setBody(body: NameCode) {
      this.body = body;
    },

    setVehicleUsage(vehicleUsage: NameCode) {
      this.vehicleUsage = vehicleUsage;
    },

    setFirstRegistrationYear(firstRegistrationYear: Nullable<number>) {
      this.firstRegistrationYear = firstRegistrationYear;
    },

    setInsuranceEndDate(endDate: Date) {
      this.insuranceEndDate = endDate;
    },

    setIsNoPlateNumber(value: boolean) {
      this.isNoPlateNumber = value;
    },

    setIsPlateNotFound(value: boolean) {
      this.isPlateNotFound = value;
    },

    setPlateCharacter(value: string) {
      this.plateCharacter = value.toUpperCase().split('').join(' ');
    },

    setPlateNumber(plateNumber: string) {
      this.plateNumber = plateNumber;
    },

    setCarInfo(info: Partial<Car>) {
      this.$patch(info);
    },

    setAdmeId(admeId: number) {
      this.admeId = admeId;
    },

    setCarDetailsForRenew(payload: PolicyInfoCar) {
      this.$patch(payload);
    },

    // TODO: удалить
    async save(payload: Partial<ApiFields>) {
      return await useApi().flowService.sendFormData(payload);
    },

    // TODO: удалить
    async getModels(brandCode?: string): Promise<NameCode[]> {
      const brand = brandCode || this.brand.code;
      if (!brand) return [];
      return await useApi().flowService.getModels(brand);
    },

    // TODO: удалить
    async getBrands(): Promise<NameCode[]> {
      return await useApi().flowService.getBrands();
    },

    // TODO: удалить
    async getVehicleTypes(): Promise<NameCode[]> {
      const vehicleTypes = await useApi().flowService.getVehicleTypes();
      return vehicleTypes.filter((vehicleType) => checkValidBodyCode(vehicleType.code));
    },

    // TODO: удалить
    async getVehicleSpecs(): Promise<VehicleSpecs> {
      const params = {
        brand: this.brand.code,
        model: this.model.code,
        modelYear: this.modelYear,
      };
      return await useApi().flowService.getVehicleSpecs(params);
    },

    // TODO: удалить
    async getCarInfoByChassisNumber(token: string) {
      const carInfo = await useApi().flowService.getVehicleDescriptionByChassisNumber({
        chassisNumber: this.chassisNumber,
        response_token: token,
      });
      this.setCarInfo(carInfo);
      this.setIsPlateNotFound(false);
    },
  },
});
