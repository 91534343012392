<script setup lang="ts">
import { ref, inject, defineAsyncComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { BaseButton } from '@qic/ui-components';

// TOOD remove app from widget
import router from '@/app/router';

import WhatsAppButton from '@/features/WhatsAppButton';
import HotlineButton from '@/features/HotlineButton';

import { useQuoteStore } from '@/entities/Stores';

import FlowCard from '@/shared/ui/FlowCard/FlowCard.vue';
import { useGoogleAnalytics } from '@/shared/composables/';

const ReloadIcon = defineAsyncComponent(() => import('@/shared/ui/icons/ReloadIcon.vue'));

interface Props {
  message: string;
}
interface Emit {
  (e: 'resolve'): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emit>();
const gaService = useGoogleAnalytics();
const isRenew = inject<boolean>('isRenew');

const landingUrl = inject<string>('landingUrl') ?? '/';
const quoteStore = useQuoteStore();

const title = ref('Something went wrong');
const desc = ref('Please try again or contact the support team if nothing changes');

const vueRouter = useRoute();
const pageName = vueRouter?.name || '';
onMounted(() => {
  gaService.errorWidgetView(
    props.message,
    pageName,
    router.currentRoute.value.redirectedFrom?.fullPath,
    title.value,
    desc.value,
    quoteStore.policyNumber,
    quoteStore.quoteNumber,
  );
});

const clickEventWhatsUp = () => {
  gaService.clickWhatsUp(
    router.currentRoute.value.redirectedFrom?.fullPath,
    title.value,
    desc.value,
    isRenew,
  );
};
const clickEventCallUs = () => {
  gaService.clickCallUs(
    router.currentRoute.value.redirectedFrom?.fullPath,
    title.value,
    desc.value,
    isRenew,
  );
};
</script>

<template>
  <FlowCard class="network-error" :with-button="false">
    <div class="network-error__content">
      <div class="network-error__message">
        <h2 class="network-error__title">
          {{ $t('error.title') }}
        </h2>
        <p class="network-error__desc">
          {{ $t('tpl_flow.error_page.text.common_error') }}
        </p>
      </div>

      <BaseButton icon="none" class="network-error__reload-btn" medium @click="emit('resolve')">
        <ReloadIcon />
        {{ $t('error.button') }}
      </BaseButton>
      <a :href="landingUrl">
        <BaseButton icon="none" small ghost>
          {{ $t('error.link') }}
        </BaseButton>
      </a>

      <div class="network-error__help">
        <h2 class="network-error__title">
          {{ $t('help_popup.open') }}
        </h2>
        <p class="network-error__desc">
          {{ $t('error.help.desc') }}
        </p>
        <WhatsAppButton @click="clickEventWhatsUp" />
        <HotlineButton @click="clickEventCallUs" />
      </div>
    </div>
  </FlowCard>
</template>

<style lang="scss" scoped>
@import 'NetworkErrorWidget';
</style>
